import React from 'react'
import styled from 'styled-components';
import { color } from '../../shared/style';
import { Banner } from './Banner';
import { Location } from './Location';
import { Question } from "./Question";
import { SendMessageForm } from "./SendMessageForm";

export const ContactContainer = ({ data }) => {
  return (
    <WrapperLayout>
      <Banner
        primary={
          data?.body?.find(
            (item) => item.__typename === "PrismicContactBodyBanner"
          )?.primary
        }
        twitter={data?.twitter}
        linkedin={data?.linkedin}
        facebook={data?.facebook}
        instagram={data?.instagram}
      />
      <SendMessageForm
        data={data?.body2[0]}
        url={process.env.GATSBY_MAILCHIMP_URL}
      />
      <Location
        items={data.body1[0] === undefined ? {} : data.body1[0]?.items}
        primary={data.body1[0] === undefined ? {} : data.body1[0]?.primary}
        address={data?.general_address?.text}
        email={data?.general_email?.text}
      />
      {/* hidden Faq in phase1 */}
      {data.body[2] && (
        <Question data={data.body[2] === undefined ? {} : data.body[2]} />
      )}
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  margin-bottom: 100px;
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 90px;
  background-color: ${color.primary.blue05};
  position: relative;
`;