import React, { useState } from 'react'
import styled from 'styled-components'
import { LocationMap } from '../../../components/Map/LocationMap'
import { Text } from '../../../components/Text/Text'
import { color, typography } from '../../../shared/style'
import { Address } from './Address'
import { List } from './List'

export const LocationSection = ({ listItem }) => {

  const [detail, setDetail] = useState(listItem[0]);

  return (
    <WrapperLocation>
      <WrapperLocationList>
        <List items={listItem} onSetDetail={setDetail} />
      </WrapperLocationList>
      <WrapperLocationDetail>
        <Title
          tag="h5"
          weight={typography.weight.regular1}
          lineHeight={36}
          font={typography.type.code}
          color={color.dark.dark100}
          capital="uppercase"
        >
          {detail?.city?.document?.data?.city?.text}
        </Title>
        <Label
          size={typography.size.s2}
          color={color.dark.dark100}
          lineHeight={22}
          font={typography.type.primary}
        >
          {detail?.city?.document?.data?.country?.text}
        </Label>
        <Span></Span>
        <Address
          address={detail?.city?.document?.data?.body5[0]?.primary?.address?.text}
          mail={detail?.city?.document?.data?.body5[0]?.primary?.email?.text}
          phone_number={detail?.city?.document?.data?.body5[0]?.primary?.phone_number?.text}
          underscore
        />
        <LocationMap geolocations={[detail?.city?.document?.data?.geo_location]} actived={true} page="contact" />
      </WrapperLocationDetail>
    </WrapperLocation>
  )
}

const WrapperLocation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  .email-margin {
    margin-bottom: 85px;
    @media (max-width: 1200px) {
      margin-bottom: 40px;
    }
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }
`;

const WrapperLocationList = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const WrapperLocationDetail = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

const Title = styled(Text)`

      `
const Label = styled(Text)`
      letter-spacing: 20%;
      `
const Span = styled.span`
      width: 100%;
      opacity: 0.1;
      border: 1px solid #000000;
      margin: 13px 0 18px 0;
      `