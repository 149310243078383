import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Text } from '../../../components/Text/Text';
import { Link } from '../../../components/Link/Link';
import { color, typography } from '../../../shared/style';
import Modal from '../../../components/Modal/modal';
import { ModalContact } from '../ModalContact';
// import useApp from '../../../contexts/context';

export const Address = ({ address, mail, phone_number, underscore }) => {
  const [openContact, setOpenContact] = useState(false);
  // const { openConta, handleOpenModal } = useApp();
  const handleOpenModal = useCallback(() => {
    setOpenContact(!openContact);
  }, [openContact, setOpenContact]);

  return (
    <Wrapper>
      <WrapperModal>
        <Modal open={openContact} handleOpenModal={handleOpenModal}>
          <ModalContact handleOpenModal={handleOpenModal} data={mail} />
        </Modal>
      </WrapperModal>
      <WrapperAddress>
        <AddressLine
          weight={typography.weight.bold}
          font={typography.type.primary}
          size={typography.size.s3}
          lineHeight={26}
          color={color.netraul.black100}
        >
          {address}
        </AddressLine>
        <LinkPhone
          color={color.netraul.black100}
          lineHeight={26}
          fontSize={typography.size.s3}
          weight={typography.weight.bold}
          type="tel"
        >
          {phone_number}
        </LinkPhone>
        <Email
          onClick={handleOpenModal}
          weight={typography.weight.bold}
          lineHeight={26}
          font={typography.type.primary}
          className="email-margin"
          color={color.dark.dark100}
          underscore={underscore}
        >
          {mail}
        </Email>
      </WrapperAddress>
    </Wrapper>
  );
};

const LinkPhone = styled(Link)`
  @media (max-width: 650px) {
    margin-top: 8px;
  }
`;
const Wrapper = styled.div``;
const WrapperModal = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 101;
  /* @media (max-width: 649px) {
    display: none;
  } */
`;

const WrapperAddress = styled.div`
  display: flex;
  flex-direction: column;
`;
const AddressLine = styled(Text)``;

const Email = styled(Link)`
  margin-top: 8px;
  border-bottom: ${props => props.underscore && '1px solid #172940;'};
  width: ${props => props.underscore && "fit-content"}
`;