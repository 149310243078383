import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import { Button } from "../../components/Button/Button";

export const SendMessageForm = ({ data, url }) => {
  const { items, primary } = data || {};
  const [checked, setChecked] = useState(false);
  const [errors, setError] = useState({ name: null, email: null });
  const [dataForm, setData] = useState({ FNAME: "", EMAIL: "", message: "" });
  const changeChecked = (e) => {
    setChecked(!checked);
  };
  const onChangeData = (e) => {
    setData({ ...dataForm, [e.target.name]: e.target.value });
  };

  //check is email
  const isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    if (dataForm.FNAME === "" && dataForm.EMAIL === "") {
      setError({
        ...errors,
        FNAME: "name is require!",
        EMAIL: "email is require!",
      });
      return e.preventDefault();
    } else if (dataForm.FNAME === "" && dataForm.EMAIL !== "") {
      if (!isEmail(dataForm.EMAIL)) {
        setError({
          ...errors,
          EMAIL: "This field must be email",
          FNAME: "name is require!",
        });
      } else {
        setError({
          ...errors,
          EMAIL: null,
          FNAME: "name is require!",
        });
      }
      return e.preventDefault();
    } else if (dataForm.EMAIL === "" && dataForm.FNAME !== "") {
      setError({ ...errors, EMAIL: "email is require!", FNAME: null });
      return e.preventDefault();
    } else {
      if (!isEmail(dataForm.EMAIL)) {
        setError({
          ...errors,
          EMAIL: "This field must be email",
        });
        return e.preventDefault();
      } else {
        setError({ ...errors, EMAIL: null, FNAME: null });
        return true;
      }
    }
  };
  useEffect(() => {
    if (items.length !== 0) {
      let arr = {};
      items.forEach((item) => {
        if (item.require) {
          arr = { ...arr, [item.field_name.text]: "" };
        }
      });
      setError(arr);
    }
  }, [items]);
  if (!data) return null;
  return (
    <WrapperSection>
      <Content>
        <Title
          tag="h4"
          weight={typography.weight.regular1}
          font={typography.type.code}
          color={color.netraul.black100}
          capital="uppercase"
        >
          {primary?.title?.text}
        </Title>
        <Form
          onSubmit={handleSubmit}
          method="post"
          target="_blank"
          action={url}
          //url: "https://breakthroughcon.us19.list-manage.com/subscribe/post?u=42460404aa082dee4b8bc1d6c&amp;id=277fc12171"
        >
          {items &&
            items.map((item, i) => (
              <>
                {item?.field_name?.text.toLowerCase() === "message" ? (
                  <InputMessage
                    placeholder={item?.field_name_placeholder?.text}
                    name={item?.field_name?.text}
                    onChange={onChangeData}
                  ></InputMessage>
                ) : (
                  <Input
                    placeholder={item?.field_name_placeholder?.text}
                    name={item?.field_name?.text}
                    onChange={onChangeData}
                  ></Input>
                )}
                {item.require && errors[item.field_name.text] && (
                  <LabelError
                    font={typography.type.primary}
                    size={typography.size.s1}
                  >
                    {errors[item.field_name.text]}
                  </LabelError>
                )}
              </>
            ))}
          {/* <Input
            placeholder="Your email address"
            name="email"
            onChange={onChangeData}
          ></Input>
          {errors.email && (
            <LabelError
              font={typography.type.primary}
              size={typography.size.s1}
            >
              {errors.email}
            </LabelError>
          )}
          <InputMessage
            placeholder="Your message"
            name="message"
          ></InputMessage> */}
          <WrapperCheckbox>
            <Checkbox
              type="checkbox"
              id="checkbox"
              checked={checked}
              onChange={changeChecked}
            ></Checkbox>
            <LabelCheckbox htmlFor="checkbox">
              <Label
                weight={typography.weight.regular}
                font={typography.type.primary}
                color={color.netraul.black100}
                size={typography.size.s2}
                lineHeight={22}
              >
                {primary?.checkbox_description?.text}
              </Label>
            </LabelCheckbox>
          </WrapperCheckbox>
          <WrapButton>
            <ButtonSubmit type="primary" disable={!checked}>
              {primary?.button_label?.text}
            </ButtonSubmit>
          </WrapButton>
        </Form>
      </Content>
    </WrapperSection>
  );
};

const WrapButton = styled.div``;
const WrapperSection = styled.div`
  width: 422px;
  height: auto;
  background-color: ${color.primary.white};
  padding: 40px 30px 32px;
  position: absolute;
  top: 221px;
  right: 130px;
  @media (max-width: 1150px) and (min-width: 1001px) {
    right: 60px;
  }
  @media (max-width: 1000px) and (min-width: 650px) {
    position: initial;
    width: auto;
    margin: 0 40px;
    margin-top: -100px;
  }
  @media (max-width: 649px) {
    position: initial;
    width: auto;
    padding: 30px 20px 37px;
    margin: 0 20px;
    margin-top: -100px;
  }
`;

const Title = styled(Text)`
  @media (max-width: 650px) {
    color: ${color.primary.blue};
    font-size: 56px !important;
    font-weight: 500;
    line-height: 56px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-top: 20px;
  border-radius: 50px;
  background: ${color.primary.blue05};
  outline: none;
  border: none;
  height: 55px;
  padding-left: 20px;
  font-weight: 400;
  font-size: 16px;
  font-family: ${typography.type.primary};
  color: ${color.netraul.black50};
  line-height: 26px;
`;

const InputMessage = styled.textarea`
  margin-top: 20px;
  border-radius: 30px;
  background: ${color.primary.blue05};
  outline: none;
  border: none;
  height: 100px;
  padding-left: 20px;
  padding-top: 20px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  font-family: ${typography.type.primary};
  color: ${color.netraul.black50};
  line-height: 26px;
  resize: none;
`;

const WrapperCheckbox = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  @media (max-width: 650px) {
    align-items: flex-start;
  }
`;

const LabelCheckbox = styled.label`
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    width: 24px;
    height: 24px;
    -webkit-appearance: none;
    background: rgba(23, 41, 64, 0.1);
    border-radius: 5px;
    border: none;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
  }
`;
const Checkbox = styled.input`
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
  &:checked + ${LabelCheckbox}:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const Label = styled(Text)`
  margin-left: 12px;
`;

const ButtonSubmit = styled(Button)`
  padding: 20px 45px;
  border-radius: 40px;
  font-family: ${typography.type.primary};
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  span {
    white-space: normal;
  }
`;

const LabelError = styled(Text)`
  color: #ad0707;
  padding-left: 20px;
`;
