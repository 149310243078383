import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { Text } from "../../../components/Text/Text";
import { color, typography } from "../../../shared/style";
import htmlSerializer from "../../SpaceConntainer/RichtextHelper/CustomText";
import { Address } from "./Address";
import { LocationSection } from "./Location";
import PropTypes from "prop-types";

export const Location = ({ items, primary, address, email }) => {
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <WrapperSectionTop>
        <WrapperContent>
          <Title
            tag="h3"
            weight={typography.weight.regular1}
            lineHeight={90}
            font={typography.type.code}
            color={color.primary.blue}
            capital="uppercase"
          >
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          <Description
            weight={typography.weight.regular}
            lineHeight={26}
            font={typography.type.primary}
            size={typography.size.s3}
            color={color.netraul.black100}
          >
            <RichText
              render={primary?.description?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Description>
        </WrapperContent>
        <WrapperGeneral>
          <Label
            size={typography.size.s2}
            color={color.netraul.black100}
            lineHeight={22}
            font={typography.type.primary}
            capital="uppercase"
          >
            For general Enquiries
          </Label>
          <Span></Span>
          <Address address={address} mail={email} />
        </WrapperGeneral>
      </WrapperSectionTop>
      <WrapperSectionBottom>
        <LocationSection listItem={items} />
      </WrapperSectionBottom>
    </WrapperSection>
  );
};

Location.propsTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      email: PropTypes.shape({
        text: PropTypes.string,
      }),
      country: PropTypes.shape({
        text: PropTypes.string,
      }),
      city: PropTypes.shape({
        text: PropTypes.string,
      }),
      address: PropTypes.shape({
        text: PropTypes.string,
      }),
      geo_location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
    })
  ),
  primary: PropTypes.shape({
    address: PropTypes.shape({
      text: PropTypes.string,
    }),
    description: PropTypes.shape({
      text: PropTypes.string,
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    }),
    email: PropTypes.shape({
      text: PropTypes.string,
    }),
    title: PropTypes.shape({
      text: PropTypes.string,
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    }),
  }),
};

const WrapperSection = styled.div`
  padding: 80px 131px 124px 131px;
  @media (max-width: 1200px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 1000px) and (min-width: 650px) {
    padding: 60px 30px 30px 30px;
  }
  @media (max-width: 649px) {
    padding: 60px 20px;
  }
`;
const WrapperSectionTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 45px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media (max-width: 649px) {
    flex-direction: column;
  }
`;
const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  @media (max-width: 649px) {
    width: 100%;
  }
`;
const WrapperGeneral = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 649px) {
    width: 100%;
  }
`;
const WrapperSectionBottom = styled.div`
  margin-top: 60px;
  @media (max-width: 649px) {
    margin-top: 25px;
  }
`;
const Title = styled(Text)`
  max-width: 600px;
  @media (max-width: 1200px) {
    font-size: 62px !important;
    line-height: 62px;
  }
  @media (max-width: 649px) {
    font-size: 36px !important;
    line-height: 36px;
    margin-bottom: 10px;
  }
`;
const Description = styled(Text)`
  margin-top: 22px;
`;
const Label = styled(Text)`
  letter-spacing: 0.2em;
`;
const Span = styled.span`
  width: 100%;
  opacity: 0.1;
  border: 1px solid #000000;
  margin: 13px 0 18px 0;
`;
