import { useStaticQuery, graphql } from "gatsby"

export const useQueryContact = () => {
  const { allPrismicContact } = useStaticQuery(
    graphql`
      query QueryContact {
        allPrismicContact {
          edges {
            node {
              data {
                general_address {
                  html
                  text
                  raw
                }
                general_email {
                  html
                  text
                  raw
                }
                phone_number {
                  html
                  text
                  raw
                }
                facebook {
                  url
                  link_type
                }
                instagram {
                  url
                  link_type
                }
                twitter {
                  url
                  link_type
                }
                linkedin {
                  url
                  link_type
                }
                body1 {
                  ... on PrismicContactBody1Locations {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      description {
                        html
                        text
                        raw
                      }
                    }
                    items {
                      city {
                        document {
                          ... on PrismicLocationSpace {
                            data {
                              city {
                                html
                                text
                                raw
                              }
                              country {
                                html
                                text
                                raw
                              }
                              geo_location {
                                latitude
                                longitude
                              }
                              thumbnail {
                                url
                              }
                              body5 {
                                ... on PrismicLocationSpaceBody5AddressAndContacts {
                                  primary {
                                    address {
                                      html
                                      text
                                      raw
                                    }
                                    email {
                                      html
                                      text
                                      raw
                                    }
                                    phone_number {
                                      html
                                      text
                                      raw
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                body {
                  ... on PrismicContactBodyBanner {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      description {
                        html
                        text
                        raw
                      }
                      banner {
                        url
                        alt
                      }
                      mail_receive {
                        html
                        text
                        raw
                      }
                    }
                  }
                  ... on PrismicContactBodyQuestions {
                    id
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                    }
                    items {
                      question {
                        html
                        text
                        raw
                      }
                      answer {
                        html
                        text
                        raw
                      }
                    }
                  }
                }
                body2 {
                  ... on PrismicContactBody2ContactForm {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      checkbox_description {
                        html
                        text
                        raw
                      }
                      button_label {
                        html
                        text
                        raw
                      }
                    }
                    items {
                      field_name {
                        html
                        text
                        raw
                      }
                      field_name_placeholder {
                        html
                        text
                        raw
                      }
                      require
                    }
                  }
                }
                body3 {
                  ... on PrismicContactBody3GeneralCard {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      robot {
                        html
                        text
                        raw
                      }
                      image {
                        url
                        alt
                      }
                      googlebot {
                        html
                        text
                        raw
                      }
                      favicon {
                        alt
                        url
                      }
                      description {
                        html
                        text
                        raw
                      }
                      bingbot {
                        html
                        text
                        raw
                      }
                    }
                    slice_type
                  }
                  ... on PrismicContactBody3OpenGraph {
                    primary {
                      og_url {
                        url
                        type
                        link_type
                      }
                      og_type {
                        html
                        text
                        raw
                      }
                      og_title {
                        html
                        text
                        raw
                      }
                      og_site_name {
                        html
                        text
                        raw
                      }
                      og_locale {
                        html
                        text
                        raw
                      }
                      og_image_width {
                        html
                        text
                        raw
                      }
                      og_image_height {
                        html
                        text
                        raw
                      }
                      og_image {
                        alt
                        url
                      }
                      og_description {
                        html
                        text
                        raw
                      }
                    }
                    slice_type
                  }
                  ... on PrismicContactBody3TwitterCard {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      image {
                        alt
                        url
                      }
                      description {
                        html
                        text
                        raw
                      }
                    }
                    slice_type
                  }
                  ... on PrismicContactBody3PinterestProductPin {
                    slice_type
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      description {
                        html
                        text
                        raw
                      }
                      price
                      currency
                      availability
                    }
                  }
                }
              }
            }
          }
        }
      }
      `
  )

  return {
    allPrismicContact
  }
}