import React from "react"
import styled from "styled-components"
import { Text } from "../../../components/Text/Text";
import { Address } from './Address';
import { Button } from "../../../components/Button/Button";
import { color, typography } from "../../../shared/style";

export const LocationDetail = ({ handleOpenModal, data }) => {

  return (
    <WrapperDetail>
      <Avatar src={data?.city?.document?.data?.thumbnail?.url}></Avatar>
      <WrapperContent>
        <City
          tag="h5"
          font={typography.type.code}
          size={typography.size.l1}
          lineHeight={36}
          weight={typography.weight.regular1}
          color={color.dark.dark100}
          capital="uppercase"
        >
          {data?.city?.document?.data?.city?.text}
        </City>
        <Country
          font={typography.type.primary}
          size={typography.size.s2}
          lineHeight={22}
          weight={typography.weight.regular}
          color={color.dark.dark100}
        >
          {data?.city?.document?.data?.country?.text}
        </Country>
        <Address
          address={data?.city?.document?.data?.body5[0]?.primary?.address?.text}
          mail={data?.city?.document?.data?.body5[0]?.primary?.email?.text}
          phone_number={
            data?.city?.document?.data?.body5[0]?.primary?.phone_number?.text
          }
        />
        <WrapperButton>
          <ButtonClose type="outline_primary" onClick={handleOpenModal}>
            Close
          </ButtonClose>
        </WrapperButton>
      </WrapperContent>
    </WrapperDetail>
  );
}

const WrapperDetail = styled.div`
  width: 90%;
  position: absolute;
  z-index: 100;
  background-color: white;
`

const WrapperContent = styled.div`
  padding: 20px;
  padding: 20px;
    display: flex;
    flex-direction: column;
`

const Avatar = styled.img`
  width: 100%;
`

const City = styled(Text)`

`

const Country = styled(Text)`
  margin-bottom: 10px;
`

const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
`
const ButtonClose = styled(Button)`
  border-radius: 20px;
  border: 1px solid #204370;
  color: #2A2A2A;
  padding: 7px 32px;
`