import React from "react";
import styled from "styled-components";
import banner from "../../images/banner-contact.png";
import { color, typography } from "../../shared/style";
import Fade from "react-reveal/Fade";
import { Text } from "../../components/Text/Text";
import { Button } from "../../components/Button/Button";
import { navigate } from 'gatsby';
import PropTypes from "prop-types";
import facebook_contact from "../../images/facebook_contact.svg";
import facebook_contact_active from "../../images/facebook_contact_active.svg";
import linkedin_contact from "../../images/linkedin_contact.svg";
import linkedin_contact_active from "../../images/linkedin_contact_active.svg";
import twitter_contact from "../../images/twitter_contact.svg";
import twitter_contact_active from "../../images/twitter_contact_active.svg";
import instagram_contact from "../../images/instagram_contact.svg";
import instagram_contact_active from "../../images/instagram_contact_active.svg";
import { redirectPage } from "../../hooks/redirect";

export const Banner = ({ primary, linkedin, facebook, instagram, twitter }) => {
  return (
    <WrapperSection banner={primary?.banner?.url}>
      <Content>
        <TextExtend>
          <Fade distance="10px" bottom duration={2000}>
            <Text font={typography.type.code}
              tag="h3"
              color={color.primary.white}
              capital="uppercase"
              weight={typography.weight.regular1}
              lineHeight={62}
              align="center">{primary.title.text}</Text>
          </Fade>
        </TextExtend>
        <Description
          color={color.primary.white}
          weight={typography.weight.bold}
          align="center"
          font={typography.type.primary}
          lineHeight={26}
        >
          {primary?.description?.text}
        </Description>
        <GroupButton>
          {linkedin && <ButtonIcon
            onClick={() => redirectPage({
              url: linkedin?.url,
              type: linkedin?.type,
              link_type: linkedin?.link_type
            })}
            key={1}
            type="transparent"
            // icon={item?.icon?.url}
            icon_default={linkedin_contact}
            icon_active={linkedin_contact_active}
          >
          </ButtonIcon>}
          {facebook && <ButtonIcon
            onClick={() => redirectPage({
              url: facebook?.url,
              type: facebook?.type,
              link_type: facebook?.link_type
            })}
            key={2}
            type="transparent"
            // icon={item?.icon?.url}
            icon_default={facebook_contact}
            icon_active={facebook_contact_active}
          >
          </ButtonIcon>}
          {twitter && <ButtonIcon
            onClick={() => redirectPage({
              url: twitter?.url,
              type: twitter?.type,
              link_type: twitter?.link_type
            })}
            key={3}
            type="transparent"
            // icon={item?.icon?.url}
            icon_default={twitter_contact}
            icon_active={twitter_contact_active}
          >
          </ButtonIcon>}
          {instagram && <ButtonIcon
            onClick={() => redirectPage({
              url: instagram?.url,
              type: instagram?.type,
              link_type: instagram?.link_type
            })}
            key={4}
            type="transparent"
            icon_default={instagram_contact}
            icon_active={instagram_contact_active}
          >
          </ButtonIcon>}
        </GroupButton>
      </Content>
    </WrapperSection>
  );
};

Banner.propsTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        url: PropTypes.string
      }),
      link: PropTypes.shape({
        url: PropTypes.string
      })
    })
  ),
  primary: PropTypes.shape({
    banner: PropTypes.shape({
      url: PropTypes.string
    }),
    description: PropTypes.shape({
      text: PropTypes.string
    }),
    mail_receive: PropTypes.shape({
      text: PropTypes.string
    }),
    title: PropTypes.shape({
      text: PropTypes.string
    })
  })
}

const GroupButton = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
`;
const ButtonIcon = styled(Button)`
  padding: 0 !important;
  border-radius: 50%;
  height: 63px;
  width: 63px;
  white-space: nowrap;
  margin: 0 9px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  flex-direction: column;
  background-image: url(${(props) => props.icon_default && props.icon_default});
  &:hover {
    background-image: url(${(props) => props.icon_active && props.icon_active});
    /* transform: scale(1.1);
    transition: transform 0.5s; */
    border: none;
  }
  &:first-child {
    margin-left: 0;
  }
  @media (max-width: 1000px) {
    /* height: 55px;
    width: 55px; */
  }
`;
const TextExtend = styled.div`
  .react-reveal {
    @media (max-width: 650px) {
      display: flex;
    }
  }
  .react-reveal span {
    @media (min-width: 1200px) {
      line-height: 90px;
    }
  }
  @media (max-width: 1200px) and (min-width: 650px) {
    width: auto;
    line-height: 62px;
  }
`;
const Description = styled(Text)`
  text-align: start;
  margin-top: 12px;
  @media (max-width: 1200px) and (min-width: 650px) {
    width: 40%;
  }
  @media (max-width: 650px) {
    font-weight: 400;
    text-align: center;
    width: inherit;
  }
`;
const WrapperSection = styled.div`
  height: 700px;
  max-width: 1500px;
  box-sizing: border-box;
  background-image: url(${(props) => (props.banner ? props?.banner : banner)});
  background-size: cover;
  background-position: center;
  width: 100%;
  margin-top: -90px;
  @media (max-width: 650px) {
    display: flex;
    justify-content: center;
  }
`;
const Content = styled.div`
  display: flex;
  position: relative;
  width: 404px;
  flex-direction: column;
  height: 100%;
  padding-top: 221px;
  padding-left: 128px;
  @media (max-width: 1150px) and (min-width: 1001px) {
    padding-left: 60px;
  }
  @media (max-width: 1000px) and (min-width: 650px) {
    width: 100%;
  }
  @media (max-width: 650px) {
    width: 80%;
    padding-left: 0;
    padding-top: 0;
    align-items: center;
    justify-content: center;
  }
`;



