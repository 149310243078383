import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../components/Text/Text';
import { color, typography } from '../../shared/style';
import PropTypes from "prop-types";
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../SpaceConntainer/RichtextHelper/CustomText';

export const Question = ({ data }) => {
  const { items, primary } = data || {};
  const [active, setActive] = useState();
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <Title
        font={typography.type.code}
        tag="h3"
        weight={typography.weight.regular1}
        lineHeight={90}
        align="center"
        color={color.primary.blue}
        capital="uppercase"
      >
        {primary?.title?.text}
      </Title>
      <WrapperContent>
        {items &&
          items?.map((item, i) => {
            return (
              <ItemExpand key={i} isActive={active && active[i]}>
                <ItemTitle
                  isActive={active && active[i]}
                  onClick={() => {
                    setActive({ ...active, [i]: active ? !active[i] : true });
                  }}
                >
                  <TextTitle
                    isActive={active && active[i]}
                    font={typography.type.primary}
                    weight={typography.weight.bold}
                    lineHeight={26}
                    size={typography.size.s3}
                  >
                    {item?.question?.text}
                  </TextTitle>
                  <Icon
                    isActive={active && active[i]}
                    font={typography.type.primary}
                    weight={typography.weight.regular1}
                    lineHeight={26}
                    size={typography.size.m2}
                  >
                    {active && active[i] ? "-" : "+"}
                  </Icon>
                </ItemTitle>
                <ItemContent isActive={active && active[i]}>
                  <RichText
                    render={item?.answer?.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </ItemContent>
              </ItemExpand>
            );
          })}
      </WrapperContent>
    </WrapperSection>
  );
};

Question.propsTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.shape({
        text: PropTypes.string,
      }),
      answer: PropTypes.shape({
        text: PropTypes.string,
        raw: PropTypes.string,
      }),
    })
  ),
};

const WrapperSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 150px 0 0 0;
  margin-bottom: 150px;
  background-color: ${color.primary.white};
  @media (max-width: 649px) {
    padding: 24px 0 0 0;
    margin-bottom: 80px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 40px;
  @media (max-width: 649px) {
    padding: 0 20px;
    width: auto;
    font-size: 62px;
    line-height: 62px;
  }
`;
const WrapperContent = styled.div`
  max-width: 875px;
  width: 100%;
  @media (max-width: 895px) {
    max-width: 100%;
    width: -webkit-fill-available;
    margin: 0 20px;
  }
`;

const ItemExpand = styled.div`
  margin: 3px 0;
`;

const ItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 21px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isActive ? color.primary.blue : color.primary.blue05};
`;
const ItemContent = styled.div`
  /* color: ${color.netraul.black100};
  display: ${(props) => (props.isActive ? "block" : "none")};
  background-color: ${color.primary.white};
  padding: 18px 30px 30px 26px;
  line-height: 26px; */
  /* display: ${(props) => (props.isActive ? "block" : "none")};
  background-color: ${color.primary.white};
  padding: 18px 30px 30px 26px;
  line-height: 26px; */
  color: ${color.netraul.black100};
  /* display: ${props => props.isActive ? 'block' : 'none'}; */
  background-color: ${color.primary.white};
  /* padding: 0 30px 0 26px; */
  line-height: 26px;
  max-height: ${props => props.isActive ? "100%" : "0"};
  overflow: hidden;
  padding: ${props => props.isActive ? "18px 30px 30px 26px" : "0 30px 0 26px"};
  transition: padding 0.5s ease-out;
`;

const TextTitle = styled(Text)`
  cursor: pointer;
  color: ${props => props.isActive ? color.primary.white : color.netraul.black100};
`

const Icon = styled(Text)`
  cursor: pointer;
  color: ${props => props.isActive ? color.primary.white : color.dark.dark50};
`