import React from 'react'
import styled from 'styled-components'
import { Text } from '../../../components/Text/Text';
import { color, typography } from '../../../shared/style';
// import { Address } from './Address';

const ListItem = ({ children, item, active, index, onClick, ...props }) => {

  return (
    <Div>
      <Li
        onClick={() => {
          onClick()
        }}
        isActive={active === index}
        {...props}
      >
        <WrapperLink>
          <City
            font={typography.type.primary}
            size={typography.size.s3}
            weight={typography.weight.bold}
            color={active === index ? color.primary.white : color.netraul.black100}
            lineHeight={26}
          >
            {item?.city?.document?.data?.city?.text}
          </City>
          <Country
            font={typography.type.primary}
            size={typography.size.s2}
            weight={typography.weight.regular}
            color={active === index ? color.primary.blue40 : color.netraul.black60}
            lineHeight={22}
          >
            {item?.city?.document?.data?.country?.text}
          </Country>
        </WrapperLink>
      </Li>
      {/* <WrapperInfo isActive={active === index} isLeft={(index !== 0 && index % 2 !== 0)}>
        <Address address={item?.address?.text} mail={item?.email?.text} />
      </WrapperInfo> */}
    </Div>
  )
}

const Div = styled.div`
  width: 25%;
  @media(max-width: 800px) and (min-width:650px) {
    width: 33%;
  }
  @media (max-width: 649px) {
    width: 50%;
  }
`;

const Li = styled.li`
    list-style:none;
    width: 90%;
    height: 70px;
    padding: 10px 0 0 16px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: ${props => props.isActive && color.primary.blue};
    @media(max-width: 1000px) and (min-width:650px) {
        
    }
    @media(max-width: 649px) {
        width: 90%;
        padding: 10px 0 0 10px;
        margin-left: -10px;
    }
`;
const WrapperLink = styled.div`
    display: flex;
    flex-direction: column;
`
// const WrapperInfo = styled.div`
//     display:${props => props.isActive ? 'block' : 'none'} ;
//     width: calc(100vw - 40px);
//     padding: 18px 0;
//     margin-top: 6px;
//     border-top: 1px solid rgba(0, 0, 0, 0.1);
//     margin-left: ${props => props.isLeft ? '-100%' : 0};
//     left: 0;
//   @media (min-width: 650px) {
//     display: none;
//   }
// `;

const City = styled(Text)`
  cursor: pointer;
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Country = styled(Text)`
cursor: pointer;
`;

const StyledListItem = styled(ListItem)`
`

export default StyledListItem