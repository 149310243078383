import React, { useState } from 'react'
import { Box } from 'rebass/styled-components'
import styled from 'styled-components'
import ListItem from "./ListItem"
import Modal from "../../../components/Modal/modal"
import { LocationDetail } from './LocationDetail'
import useApp from '../../../contexts/context'
import {SortNameArray} from '../../../components/SortNameArray/SortNameArray'


export const List = ({ children, items, onSetDetail, ...props }) => {
  const { open, handleOpenModal } = useApp();
  const [active, setActive] = useState(0);
  const [detail, setDetail] = useState();
  const list = SortNameArray(items);
  return (
    <Wrapper>
      <WrapperModal>
        <Modal open={open} handleOpenModal={handleOpenModal}>
          <LocationDetail handleOpenModal={handleOpenModal} data={detail} />
        </Modal>
      </WrapperModal>
      <BoxUl as="ul" px={0} pb={3} {...props}>
        {list && list.map((item, i) =>
          <ListItem
            onClick={() => {
              setActive(i);
              onSetDetail(item);
              handleOpenModal();
              setDetail(item)
            }}
            key={i}
            item={item}
            index={i}
            active={active}
            {...props}
          >
          </ListItem>
        )}
      </BoxUl>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  @media (min-width: 650px) {
    margin-left: -16px;
  }
`;
const WrapperModal = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  @media (min-width: 800px) {
    display: none;
  }
`;
const BoxUl = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  @media (max-width: 1000px) and (min-width: 650px){
   
  }
  @media (max-width: 649px) {
    
  }
`